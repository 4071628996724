import React, { useState, useEffect } from 'react'
import firebase from '../firebase'
import { Link } from 'react-router-dom'
import _ from 'lodash'

const Brand = ({ id }) => {

    const [brand,setBrand] = useState({})

    useEffect(() => {
        if (id) {
            firebase.getDocumentOnce('brands',id).then(snap => {
                setBrand(snap.data())
            })
        }
    },[id])

    return (
        <picture style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <img style={{ height: '4rem' }} src={brand.logo} alt={brand.name} />
        </picture>
    )
}

const Item = ({ product }) => {

    const images = product.images ? _.orderBy(product.images) : []

    return (
        <div style={{ maxWidth: '40rem' }} className="product">
            <picture>
                <img src={images[0] ? images[0].file : ''} alt={product.name} />
            </picture>
            <div className="product__container">
                <h4>{product.name}</h4>
                {product.brandId ? <Brand id={product.brandId} /> : ''}
                <p>{product.description ? product.description.substring(0,150) : ''} ...</p>
                <Link to={`/equipos/${product.id}`}>Ver más <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
        </div>
    )
}

export default function Products () {

    const [products,setProducts] = useState([])
    const [seeker,setSeeker] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getCollectionRealTime('productsAndServices').where('type','==','product').where('status','==','active').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setProducts(data)
        })
    },[])

    const search = seeker ? _.filter(products, item => {
        return _.lowerCase(item.model + ' ' + item.name + ' ' + item.description).match(_.lowerCase(seeker))
    }) : products

    return (
        <div className="products">
            <div className="products__container">
                <form onSubmit={e => e.preventDefault() && false}>
                    <input value={seeker} onChange={e => setSeeker(e.target.value)} placeholder="Buscar ..." type="text"/>
                    <i className="material-icons">search</i>
                </form>
                <ul>
                    {
                        search.map(item => <Item key={item.id} product={item} />)
                    }
                </ul>
            </div>
        </div>
    )
}