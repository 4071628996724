import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../firebase'
import _ from 'lodash'

const Item = ({ id }) => {

    const [product,setProduct] = useState({})

    useEffect(() => {
        firebase.getDocumentOnce('productsAndServices',id).then(snap => {
            setProduct(snap.data())
        })
    },[id])

    const images = product.images ? _.orderBy(product.images) : []

    return (
        <div className="product">
            <picture>
                <img src={images[0] ? images[0].file : ''} alt={product.name} />
            </picture>
            <div className="product__container">
                <h4>{product.name}</h4>
                <p>{product.description ? product.description.substring(0,150) : ''} ...</p>
                <Link to={`/equipos/${id}`}>Ver más <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
        </div>
    )
}

export default function SingleIndustry (props) {

    const id = props.match.params.id
    const [industry,setIndustry] = useState({})

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getDocumentOnce('sections',id).then(snap => {
            setIndustry(snap.data())
        })
    },[id])

    const items = industry.items ? industry.items : []

    return (
        <div className="single-industry">
            <div style={{ backgroundImage: `url(${industry.cover})` }} className="single-industry__cover">
                <h1>{industry.name}</h1>
            </div>
            <div className="single-industry__about">
                <div className="single-industry__about-container">
                    <p>{industry.description}</p>
                    <picture>
                        <img src={industry.image} alt={industry.name} />
                    </picture>
                </div>
            </div>
            <div className="single-industry__products">
                <div className="single-industry__products-container">
                    <h3>Productos Relacionados</h3>
                    <ul>
                        {items.map(item => <Item key={item} id={item} />)}
                    </ul>
                </div>
            </div>
        </div>
    )
}