import React, { useState, useEffect } from 'react'
import firebase from '../firebase'
import { Link, Switch, Route } from 'react-router-dom'
import Home from '../pages/Home'
import moment from 'moment'
import SingleIndustry from '../pages/SingleIndustry'
import Industries from '../pages/Industries'
import Products from '../pages/Products'
import ProductsCategory from '../pages/ProductsCategory'
import SingleProduct from '../pages/SingleProduct'
import Services from '../pages/Services'
import About from '../pages/About'
import Contact from '../pages/Contact'

export default function App() {

    const [sections,setSections] = useState([])
    const [categories,setcategories] = useState([])
    const [services,setservices] = useState([])

    useEffect(() => {
        firebase.getCollectionRealTime('sections').where('pageId','==','page-1525554581176').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setSections(data)
        })
        firebase.getCollectionRealTime('categories').where('status','==','active').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setcategories(data)
        })
        firebase.getCollectionRealTime('productsAndServices').where('type','==','service').where('status','==','active').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setservices(data)
        })
    },[])

    return (
        <div className="app">
            <header>
                <div className="header__pages">
                    <div className="header__pages-container">
                        <a href="https://equiposdeelevacion.com.mx/" target="_blank">
                            <img style={{ height: '1.5rem' }} src="/images/equipos.png" alt="Polipastos Querétaro"/>
                        </a>
                        <a href="https://equipospronal.com/" target="_blank">
                            <img style={{ height: '1.5rem' }} src="/images/pronal.png" alt="Equipos PRONAL"/>
                        </a>
                    </div>
                </div>
                <div className="header__top">
                    <Link to="/">
                        <img src="/images/logo.png" alt="Equipos de Elevación"/>
                    </Link>
                    <ul>
                        <li>
                            <i className="material-icons">local_phone</i>
                            <span>+52 (55) 5365 5115</span>
                        </li>
                        <li>
                            <i className="material-icons">email</i>
                            <a href="mailto:administración@equiposdeelevacion.com.mx">administración@equiposdeelevacion.com.mx</a>
                        </li>
                    </ul>
                </div>
                <div className="header__bottom">
                    <ul>
                        <li>
                            <Link to="/industrias">Industrias</Link>
                            <div className="options">
                                {
                                    sections.map(item => <Link key={item.id} to={`/industrias/${item.id}`}>{item.name}</Link>)
                                }
                            </div>
                        </li>
                        <li>
                            <Link to="/equipos">Equipos</Link>
                            <div className="options">
                                {
                                    categories.map(item => <Link key={item.id} to={`/equipos/marcas/${item.id}`}>{item.name}</Link>)
                                }
                            </div>
                        </li>
                        <li>
                            <Link to="/servicios">Servicio</Link>
                            <div className="options">
                                {
                                    services.map(item => <Link key={item.id} to={`/servicios/${item.id}`}>{item.name}</Link>)
                                }
                            </div>
                        </li>
                        {/* <Link to="/">Piezas</Link> */}
                        <Link to="/contacto">Contacto</Link>
                    </ul>
                </div>
            </header>
            <Switch>
                <Route path="/" component={Home} exact={true} />
                <Route path="/nosotros" component={About} exact={true} />
                <Route path="/equipos" component={Products} exact={true} />
                <Route path="/equipos/marcas/:id" component={ProductsCategory} exact={true} />
                <Route path="/equipos/:id" component={SingleProduct} exact={true} />
                <Route path="/industrias" component={Industries} exact={true} />
                <Route path="/industrias/:id" component={SingleIndustry} exact={true} />
                <Route path="/servicios" component={Services} exact={true} />
                <Route path="/servicios/:id" component={SingleProduct} exact={true} />
                <Route path="/contacto" component={Contact} exact={true} />
            </Switch>
            <footer>
                <div className="footer__container">
                    <div className="footer__top">
                        <ul>
                            <Link to="/industrias">Industrias</Link>
                            <div className="options">
                                {
                                    sections.map(item => <Link key={item.id} to={`/industrias/${item.id}`}>{item.name}</Link>)
                                }
                            </div>
                        </ul>
                        <ul>
                            <Link to="/equipos">Equipo</Link>
                            <div className="options">
                                {
                                    categories.map(item => <Link key={item.id} to={`/equipos/marcas/${item.id}`}>{item.name}</Link>)
                                }
                            </div>
                        </ul>
                        <ul>
                            <Link to="/">Servicio</Link>
                            <div className="options">
                                {
                                    services.map(item => <Link key={item.id} to={`/servicios/${item.id}`}>{item.name}</Link>)
                                }
                            </div>
                        </ul>
                        <ul>
                            <div className="footer__contact">
                                <h4>Contacto</h4>
                                <p>AV. 5 DE FEBRERO NO. 795 LOCAL Y, COLONIA LA SIERRITA, SANTIAGO DE QUERÉTARO. QUERÉTARO. CP 76137.</p>
                                <span>Tel (442) 672 8872</span>
                                <a href="mailto:contacto@polipastosnacionales.com">contacto@polipastosnacionales.com</a>
                            </div>
                        </ul>
                    </div>
                    <div className="footer__bottom">
                        <picture>
                            <img src="/images/logo.png" alt="Equipos de Elevación"/>
                        </picture>
                        <ul>
                            <Link to="/">Aviso de Privacidad</Link>
                            <ul>Polipastos Nacionales Querétaro sa de cv &copy; {moment().format('YYYY')} Todos los derechos reservados.</ul>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    )
}