import React, { useEffect, useState } from 'react'
import firebase from '../firebase'
import { Link } from 'react-router-dom'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import _ from 'lodash'

const Brand = ({ id }) => {

    const [brand,setBrand] = useState({})

    useEffect(() => {
        if (id) {
            firebase.getDocumentOnce('brands',id).then(snap => {
                setBrand(snap.data())
            })
        }
    },[id])

    return (
        <picture style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <img style={{ height: '4rem' }} src={brand.logo} alt={brand.name} />
        </picture>
    )
}

const Item = ({ product }) => {

    const images = product.images ? _.orderBy(product.images) : []

    return (
        <div style={{ maxWidth: '40rem' }} className="product">
            <picture>
                <img src={images[0] ? images[0].file : ''} alt={product.name} />
            </picture>
            <div className="product__container">
                <h4>{product.name}</h4>
                {product.brandId ? <Brand id={product.brandId} /> : ''}
                <p>{product.description ? product.description.substring(0,150) : ''} ...</p>
                <Link to={`/equipos/${product.id}`}>Ver más <i className="material-icons">keyboard_arrow_right</i></Link>
            </div>
        </div>
    )
}

export default function Home () {

    const [products,setProducts] = useState([])
    const [name,setname] = useState('')
    const [email,setemail] = useState('')
    const [phone,setphone] = useState('')
    const [message,setmessage] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getCollectionRealTime('productsAndServices').where('isPrincipal','==',true).where('type','==','product').limit(6).onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setProducts(data)
        })
    },[])

    return (
        <div className="home">
            <div className="home__cover">
                <h1>Polipastos Nacionales Querétaro</h1>
                <p>Fabricación de grúas viajeras, soluciones para carga y almacenaje</p>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__highlights">
                <Link to="/nosotros">
                    <img src="/images/pnq2.png" alt="Polipastos Nacionales Querétaro"/>
                    <div className="container">
                        <h3>Solicita una demostración en vivo vía web o presencial de nuestros Polipastos Nacionales Querétaro y polipastos.</h3>
                        <h2>Polipastos Nacionales Querétaro en vivo</h2>
                        <p>¿Tienes alguna pregunta? Resuelvelas desde la comodidad de tu computadora con una demostración y asesoría en línea.</p>
                    </div>
                </Link>
                <Link style={{ marginLeft: '4.5rem' }} to="/equipos">
                    <img src="/images/ede11.png" alt="Polipastos Nacionales Querétaro"/>
                    <div className="container">
                        <h3>Conoce todos nuestros equipos y las mejores marcas de importación exclusivas de Polipastos Nacionales Querétaro</h3>
                        <h2>Todos nuestros equipos</h2>
                        <p>Observa todos nuestros equipos, ¿no encuentras alguno? preguntanos y recibe asesoría de un experto</p>
                    </div>
                </Link>
                <Link style={{ marginLeft: '4.5rem' }} to="/servicios">
                    <img src="/images/ede3.jpeg" alt="Polipastos Nacionales Querétaro"/>
                    <div className="container">
                        <h3>Equipos a la medida</h3>
                        <h2>Servicios de Polipastos Nacionales Querétaro</h2>
                        <p>Conoce nuestros servicios de construcción y creación de Polipastos Nacionales Querétaro a la medida e instalaciones especializadas</p>
                    </div>
                </Link>
            </div>
            <div className="home__products">
                <div className="home__products-container">
                    {
                        products.map(item => <Item key={item.id} product={item} />)
                    }
                </div>
            </div>
            <div className="home__separator">
                <div className="home__separator-container">
                    <div className="content">
                        <span>Acerca de nosotros</span>
                        <h2>Polipastos Nacionales Querétaro</h2>
                        <p>Somos líderes en fabricación de grúas viajeras y nos especializamos en el manejo de carga de todos los sectores.</p>
                        <Link to="/nosotros">Ver más</Link>
                    </div>
                    <picture>
                        <img src="/images/ede7.png" alt="Polipastos Nacionales Querétaro"/>
                    </picture>
                </div>
            </div>
            <div className="home__customers">
                <div className="home__customers-container">
                    <h3>Nuestros clientes</h3>
                    <ul>
                        <picture>
                            <img src="/images/customers/3b-logo.png" alt="Tiendas 3B Logo"/>
                        </picture>
                        <picture>
                            <img src="/images/customers/a-shulmal-logo.png" alt="A Shulmal Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/alpura-logo.png" alt="Logo Cliente Alpura" />
                        </picture>
                        <picture>
                            <img src="/images/customers/alutech-logo.png" alt="Alutech Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/braskem-logo.png" alt="Braskem Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/alstom-logo.png" alt="Alstom Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/Logo_comex.png" alt="Comex Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/Maire_Tecnimont.png" alt="Maire Tenimont Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/neto-logo.png" alt="Tiendas Neto Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/saint-logo.png" alt="Saint Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/technip-logo.png" alt="Technip Cliente Logo" />
                        </picture>
                        <picture>
                            <img src="/images/customers/bayer-logo.png" alt="Bayer Cliente Logo" />
                        </picture>
                    </ul>
                </div>
            </div>
            <div className="home__social">
                <div className="home__social-container">
                    <div style={{ marginRight: '2.5rem' }} className="home__social-item">
                        <div className="title">
                            <b>Prensa</b>
                            <h4>Últimas noticias</h4>
                        </div>
                    </div>
                    <div style={{ marginRight: '2.5rem' }} className="home__social-item">
                        <div className="title">
                            <b>@equiposdeelevacion</b>
                            <h4>Twitter</h4>
                        </div>
                        <TwitterTimelineEmbed
                            sourceType="profile"
                            screenName="RMHoist"
                            options={{ height: 400 }}
                        />
                    </div>
                    <div className="home__social-item">
                        <div className="title">
                            <b>#equiposdeelevacion</b>
                            <h4>Media</h4>
                        </div>
                        <ul>
                            <picture>
                                <img src="/images/media/1.jpeg" alt="1"/>
                            </picture>
                            <picture>
                                <img src="/images/media/2.jpeg" alt="2"/>
                            </picture>
                            <picture>
                                <img src="/images/media/3.jpg" alt="3"/>
                            </picture>
                            <picture>
                                <img src="/images/media/4.jpeg" alt="4"/>
                            </picture>
                            <picture>
                                <img src="/images/media/5.jpg" alt="5"/>
                            </picture>
                            <picture>
                                <img src="/images/media/6.jpeg" alt="6"/>
                            </picture>
                            <picture>
                                <img src="/images/media/7.jpeg" alt="7"/>
                            </picture>
                            <picture>
                                <img src="/images/media/8.jpg" alt="8"/>
                            </picture>
                            <picture>
                                <img src="/images/media/9.jpg" alt="9"/>
                            </picture>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="home__contact">
                <div className="home__contact-container">
                    <div className="home__contact-middle-container">
                        <form onSubmit={e => e.preventDefault() && false} >
                            <div className="home__input-container">
                                <label>Nombre</label>
                                <input value={name} onChange={e => setname(e.target.value)} type="text" />
                            </div>
                            <div className="home__input-container">
                                <label>Correo Electrónico</label>
                                <input value={email} onChange={e => setemail(e.target.value)} type="email" />
                            </div>
                            <div className="home__input-container">
                                <label>Teléfono (Opcional)</label>
                                <input value={phone} onChange={e => setphone(e.target.value)} type="text" />
                            </div>
                            <div className="home__input-container">
                                <label>Mensaje</label>
                                <textarea value={message} onChange={e => setmessage(e.target.value)}></textarea>
                            </div>
                            <button onClick={handleSubmit} type="submit">Enviar</button>
                        </form>
                    </div>
                    <div className="home__contact-middle-container">
                        <h4>Contáctanos</h4>
                        <span className="home__contact-phone"><i className="material-icons">phone</i>+52 (55) 5365 5115</span>
                    </div>
                </div>
            </section>
        </div>
    )

    async function handleSubmit () {
        try {
            if (name && email && message) {
                const item = {
                    name,
                    email,
                    phone,
                    message,
                    status: 'noRead',
                    page: 'Polipastos Querétaro'
                }
                firebase.simpleAdd(item,'messages').then(() => {
                    alert('Un asesor se pondrá en contacto contigo')
                    setname('')
                    setemail('')
                    setphone('')
                    setmessage('')
                })
            } else {
                alert('Para continuar debes agregar nombre, email y mensaje')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}