import React, { useState, useEffect } from 'react'
import firebase from '../firebase'

export default function Contact () {

    const [name,setname] = useState('')
    const [email,setemail] = useState('')
    const [phone,setphone] = useState('')
    const [message,setmessage] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="contact">
            <div className="contact__container">
                <div className="content">
                    <h1>Contacto</h1>
                    <h3>Teléfonos</h3>
                    <ul>
                        <li>(442) 672 8872</li>
                        <li>(442) 690 1215</li>
                    </ul>
                    <h3>Dirección</h3>
                    <p>AV. 5 DE FEBRERO NO. 795 LOCAL Y, COLONIA LA SIERRITA, SANTIAGO DE QUERÉTARO. QUERÉTARO. CP 76137.</p>
                    <h3>Email</h3>
                    <a href="mailto:contacto@polipastosnacionales.com">contacto@polipastosnacionales.com</a>
                </div>
                <div className="home__contact-middle-container">
                    <form onSubmit={e => e.preventDefault() && false} >
                        <div className="home__input-container">
                            <label>Nombre</label>
                            <input value={name} onChange={e => setname(e.target.value)} type="text" />
                        </div>
                        <div className="home__input-container">
                            <label>Correo Electrónico</label>
                            <input value={email} onChange={e => setemail(e.target.value)} type="email" />
                        </div>
                        <div className="home__input-container">
                            <label>Teléfono (Opcional)</label>
                            <input value={phone} onChange={e => setphone(e.target.value)} type="text" />
                        </div>
                        <div className="home__input-container">
                            <label>Mensaje</label>
                            <textarea value={message} onChange={e => setmessage(e.target.value)}></textarea>
                        </div>
                        <button onClick={handleSubmit} type="submit">Enviar</button>
                    </form>
                </div>
            </div>
        </div>
    )

    async function handleSubmit () {
        try {
            if (name && email && message) {
                const item = {
                    name,
                    email,
                    phone,
                    message,
                    status: 'noRead',
                    page: 'Polipastos Querétaro'
                }
                firebase.simpleAdd(item,'messages').then(() => {
                    alert('Un asesor se pondrá en contacto contigo')
                    setname('')
                    setemail('')
                    setphone('')
                    setmessage('')
                })
            } else {
                alert('Para continuar debes agregar nombre, email y mensaje')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}