import React from 'react'

export default function About () {

    return (
        <div className="about">
            <div className="about__cover">
                <h1>Acerca de nosotros</h1>
            </div>
            <div className="about__details">
                <div className="about__details-container">
                    <h3>¿Quiénes somos?</h3>
                    <p>Somos una empresa mexicana dedicada a la Fabricación, comercialización, instalación y mantenimiento de polipastos, montacargas, elevadores, troles manuales, imanes de carga, entre otros. Somos fabricantes de grúas viajeras, elevadores de carga, etc. Ofrecemos el mejor servicio y la más alta calidad en el menor tiempo posible.</p>
                    <h3>¿Qué hacemos?</h3>
                    <p>Además de la excelente calidad que maneja nuestra empresa, ofrecemos mantenimiento y refacciones para tus equipos, proporcionando personal técnico especializado. Tenemos la más amplia gama de grúas y contamos con tecnología propia de vanguardia con la clase de servicio "C" según la norma</p>
                    <h3>¿Cómo lo hacemos?</h3>
                    <p>Todos nuestros equipos están diseñados con sistemas electrónicos modulares, los cuales son compatibles para recibir las innovaciones que desarrollamos. La tecnología de nuestros sistemas de montaje están a la vanguardia para proporcionar a nuestros clientes mayor seguridad.</p>
                </div>
            </div>
        </div>
    )
}