import React, { useState, useEffect } from 'react'
import firebase from '../firebase'
import moment from 'moment'
import _ from 'lodash'

import BrandLogo from '../components/BrandLogo'

export default function SingleProduct (props) {

    const id = props.match.params.id
    const [product,setProduct] = useState({})
    const images = product.images ? _.orderBy(product.images) : []
    const includes = product.includes ? _.orderBy(product.includes) : []

    const [name,setname] = useState('')
    const [email,setemail] = useState('')
    const [phone,setphone] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getDocumentOnce('productsAndServices',id).then(snap => {
            setProduct(snap.data())
        })
    },[id])

    return (
        <div className="single-product">
            <div className="single-product__container">
                <h2>Galería</h2>
                <div className="single-product__gallery">
                    {images.map(item => <picture key={item.file}>
                        <img src={item.file} alt={item.description}/>
                    </picture>)}
                </div>
                <h1>{product.model} - {product.name}</h1>
                <p>{product.description}</p>
                {product.brandId ? <React.Fragment>
                    <h2>Marca</h2>
                    <BrandLogo id={product.brandId} />
                </React.Fragment> : ''}
                <h2>Tiempo de Entrega</h2>
                <p>{product.deliveryTime}</p>
                <h2>Garantía</h2>
                <p>{product.warranty}</p>
                <h2>Puntos destacados</h2>
                <ul>
                    {
                        includes.map(item => <li key={item.text}>{item.text}</li>)
                    }
                </ul>
                <h2>Recibe más información de este producto</h2>
                <form style={{ marginTop: '2rem', padding: '2.5rem', backgroundColor: '#fff' }} onSubmit={e => e.preventDefault() && false}>
                    <div className="input-container">
                        <label>Nombre (Obligatorio)</label>
                        <input placeholder="Nombre" value={name} onChange={e => setname(e.target.value)} type="text"/>
                    </div>
                    <div className="input-container">
                        <label>Email (Obligatorio)</label>
                        <input placeholder="Email" value={email} onChange={e => setemail(e.target.value)} type="mail"/>
                    </div>
                    <div className="input-container">
                        <label>Teléfono (Opcional)</label>
                        <input placeholder="Teléfono" value={phone} onChange={e => setphone(e.target.value)} type="text"/>
                    </div>
                    <div className="input-container">
                        <button onClick={handleSubmit}>Enviar solicitud de información</button>
                    </div>
                </form>
            </div>
        </div>
    )

    async function handleSubmit () {
        try {
            if (name && email) {
                const item = {
                    name,
                    email,
                    phone,
                    status: 'noRead',
                    pasId: id,
                    page: 'Polipastos Querétaro'
                }
                firebase.simpleAdd(item,'info-requests').then(() => {
                    alert('Un asesor se pondrá en contacto contigo')
                    setname('')
                    setemail('')
                    setphone('')
                })
            } else {
                alert('Para continuar debes escribir tu nombre y correo electrónico')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}