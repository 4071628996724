import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

import moment from 'moment';

const config = {
    apiKey: "AIzaSyDincxMP4erbuqOm29q5kg1VUuVdKT1F78",
    authDomain: "proyecto-equipos-de-elevacion.firebaseapp.com",
    databaseURL: "https://proyecto-equipos-de-elevacion.firebaseio.com",
    projectId: "proyecto-equipos-de-elevacion",
    storageBucket: "proyecto-equipos-de-elevacion.appspot.com",
    messagingSenderId: "695873998200",
    appId: "1:695873998200:web:f9f2a9738347555ac18c8d",
    measurementId: "G-E25B3SC0ZL"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
        this.firestore = app.firestore()
        this.storage = app.storage()
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
    }

	logout() {
		return this.auth.signOut()
	}

	async register(name, email, password) {
        const timestamp = moment().valueOf()
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		}).then(() => {
            return this.firestore.doc(`users/${this.auth.currentUser.uid}`).set({
                name,
                id: this.auth.currentUser.uid,
                isActive: false,
                email,
                password,
                timestamp,
                rol: 'user'
            })
        })
    }

    userValidator(id) {
        return new Promise(resolve => {
            this.firestore.doc(`users/${id}`).onSnapshot(snap => {
                const user = snap.data();
                resolve(user)
            });
        });
    }

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
    }

    async uploadDocumentFile(col,doc,index,file) {
        const uploadFile = (file,name,folder) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(folder).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection(col).doc(doc)
        const url = await uploadFile(file,index,doc)
        return ref.update({ [index]: url })
    }

    async addImage(file,productId) {
        const uploadFile = (file,name,folder) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(folder).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection('images').doc()
        const url_1 = await uploadFile(file, ref.id, 'products');
        const item = {
            file: url_1,
            productId,
            id: ref.id,
            timestamp: moment().valueOf()
        };
        return ref.set(item);
    }

    getCollectionOnce(col) {
        const data = this.firestore.collection(col).get()
        return data
    }
    getCollectionRealTime(col) {
        const data = this.firestore.collection(col)
        return data
    }
    getDocumentOnce(col,doc) {
        const data = this.firestore.collection(col).doc(doc).get()
        return data
    }
    getDocumentRealTime(col,doc) {
        const data = this.firestore.collection(col).doc(doc)
        return data
    }

    update(col,id,index,value) {
        return this.firestore.collection(col).doc(id).update({ [index]: value });
    }
    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        await ref.set(item);
        return ref.id
    }
    async delete(col,doc,data) {
        const ref = this.firestore.collection(col).doc(doc)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
    async deleteOperationFile(col,doc,annex,id,data) {
        const ref = this.firestore.collection(col).doc(doc).collection(annex).doc(id)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col:`/${col}/${doc}/${annex}`,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: id,
            userId: this.auth.currentUser.uid,
            data
        }
        return movesRef.set(move);
    }
}

export default new Firebase()