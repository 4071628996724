import React, { useState, useEffect } from 'react'
import firebase from '../firebase'
import { Link } from 'react-router-dom'

export default function Industries () {

    const [industries,setindustries] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getCollectionRealTime('sections').orderBy('name').where('pageId','==','page-1525554581176').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setindustries(data)
        })
    },[])

    return (
        <div className="industries">
            <div className="industries__cover">
                <div className="industries__cover-container">
                    <span>Vista General</span>
                    <h1>Industrias que abarcamos</h1>
                    <p>Conoce todas las industrias que abarcan los productos de equipos de elevación</p>
                </div>
            </div>
            <div className="industries__list">
                <div className="industries__list-container">
                    {industries.map(item => <Link to={`/industrias/${item.id}`} key={item.id}>
                        <img src={item.image} alt={item.name} />
                        <div className="title">
                            <h3>{item.name}</h3>
                        </div>
                        <div className="content">
                            <h3>{item.name}</h3>
                            <hr/>
                            <p>{item.description ? item.description.substring(0,100) : ''}...</p>
                            <span>Ver más <i className="material-icons">keyboard_arrow_right</i></span>
                        </div>
                    </Link>)}
                </div>
            </div>
        </div>
    )
}