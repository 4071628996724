import React, { useState, useEffect } from 'react'
import firebase from '../firebase'

export default function BrandLogo ({ id }) {

    const [brand,setBrand] = useState({})

    useEffect(() => {
        if (id) {
            firebase.getDocumentOnce('brands',id).then(snap => {
                setBrand(snap.data())
            })
        }
    },[id])

    return (
        <div className="brand">
            <span>{brand.name}</span>
            <picture>
                <img src={brand.logo} alt={brand.name} />
            </picture>
        </div>
    )
}